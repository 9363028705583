import styled from "styled-components";

const ToggleButton = ({ theme, toggleThemes }) => {
  const toggle = theme === "light" ? true : false;
  return (
    <>
      <Container light={toggle} onClick={toggleThemes}>
        <img src="/assets/sun.jpg" alt="" />
        <img src="/assets/moon.png" alt="" />
      </Container>
    </>
  );
};

export default ToggleButton;

const Container = styled.div`
  position: relative;
  width: 52px;
  height: 25px;
  background-color: #333;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  img {
    width: 50%;
    height: 100%;
    object-fit: contain;
  }
  &::after {
    content: "";
    position: absolute;
    top: -3px;
    right: ${(props) => (props.light ? "26px" : "-3px")};
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ccc;
    transition: all 0.3s linear;
  }
  @media screen and (max-width: 880px) {
    margin: 0 50px;
  }
`;
