import styled from "styled-components";
import { servicesIcons } from "../vsight-data";

function ServiceCard({ title, description, icon }) {
  return (
    <CardContainer>
      {servicesIcons[icon]}
      <h2>{title}</h2>
      <p>{description}</p>
    </CardContainer>
  );
}

export default ServiceCard;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  width: 32%;
  height: 320px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.cardBackground};
  margin-bottom: 20px;
  transition: all 0.4s linear;
  h2 {
    color: ${({ theme }) => theme.heading};
    font-weight: 400;
    margin: 15px 0;
  }
  p {
    color: ${({ theme }) => theme.paragraphs};
  }

  .icon {
    font-size: 2.5em;
    color: #9221c7;
  }

  &:hover {
    background: linear-gradient(to top right, #710891, #5a099c);
    transform: translateY(-5px);
    box-shadow: 0 8px 12px -6px ${({ theme }) => theme.cardShadow};
    h2,
    p,
    .icon {
      color: #eee;
    }
  }
  @media screen and (max-width: 880px) {
    width: 100%;
    margin: auto;
    margin-bottom: 15px;
    height: max-content;
  }

  @media screen and (min-width: 880px) and (max-width: 1024px) {
    width: 49%;
    margin-bottom: 15px;
  }
`;
