import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
html {
    font-size: 100%;
    box-sizing: border-box;
}

*, *::after, *::before {
    margin: 0;
    box-sizing: inherit;
}

a, p {
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
}

p, img, span, button, a {
  user-select: none;
}

button {
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  &:active {
    outline: none
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  user-select: none;
}

ul, li {
    list-style: none;
    padding-left: 0;
}

  body {
    // height: 2000px;
    overflow-x: hidden;
    font-family: 'Open Sans', sans-serif;
    background-color: ${({ theme }) => theme.backGround};
    transition: all .1s linear
  }

  canvas {
    width: 100%;
    height: 100%
  }
`;

export const lightTheme = {
  border: "#ccc",
  mainColor: "#a024da",
  burger: "#eee",
  burgerScroll: "#333",
  backGround: "#fcfcfc",
  paragraphs: "#5a5a5a",
  linkColor: "#ececec",
  linkScrollHover: "#fff",
  linkMenu: "#3b3b3b",
  backgroundOnScroll: "#eee",
  menu: "#e0e0e0",
  heading: "#333",
  cardShadow: "#777",
  cardBackground: "#eee",
  footer: "#fff",
};

export const darkTheme = {
  border: "#272727",
  mainColor: "#a024da",
  burger: "#eee",
  burgerScroll: "#eee",
  backGround: "#222",
  paragraphs: "#b3b0b0",
  linkColor: "#ececec",
  linkScrollHover: "#000",
  linkMenu: "#dbdbdb",
  backgroundOnScroll: "#111",
  menu: "#1d1d1d",
  heading: "#eee",
  cardShadow: "#000",
  cardBackground: "#111",
  footer: "#000",
};

//  ${({ theme }) => theme.headingColor}
