import Landing from "../components/Landing";
import Services from "../components/Services";
import About from "../components/About";
import Contact from "../components/Contact";
import TeamSection from "../components/TeamSection";

function Home() {
  return (
    <>
      <Landing />
      <About />
      <Services />
      <TeamSection />
      <Contact />
    </>
  );
}

export default Home;
