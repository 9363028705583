import styled from "styled-components";

function BurgerMenu({ showMenu, setShowMenu, scroll }) {
  return (
    <BurgerContainer
      scroll={scroll}
      show={showMenu}
      onClick={() => setShowMenu(!showMenu)}
    >
      <div />
      <div />
      <div />
    </BurgerContainer>
  );
}

export default BurgerMenu;

const BurgerContainer = styled.div`
  position: relative;
  z-index: 10;
  width: 30px;
  height: 16px;
  flex-direction: column;
  justify-content: space-between;
  display: none;
  cursor: pointer;

  div {
    background-color: ${(props) =>
      props.scroll
        ? ({ theme }) => theme.burgerScroll
        : ({ theme }) => theme.burger};
    height: 1.5px;
    width: 100%;
    transition: transform 0.3s linear;
    &:first-child {
      transform: ${(props) =>
        props.show
          ? "rotateZ(45deg) translateY(10px)"
          : "rotateZ(0) translateY(0)"};
    }
    &:nth-child(2) {
      display: ${(props) => (props.show ? "none" : "inline")};
    }
    &:last-child {
      transform: ${(props) =>
        props.show
          ? "rotateZ(-45deg) translateY(-10px)"
          : "rotateZ(0) translateY(0)"};
    }
  }

  @media screen and (max-width: 880px) {
    display: flex;
  }
`;

/*

*/
