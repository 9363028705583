import { GlobalStyle, lightTheme, darkTheme } from "./GlobalStyles";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { useDarkMode } from "./hooks/DarkMode";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./pages/About";
import ErrorPage from "./pages/ErrorPage";
import Blog from "./pages/Blog";

function App() {
  const [theme, toggleThemes] = useDarkMode();
  const currentTheme = theme === "light" ? lightTheme : darkTheme;

  return (
    <>
      <ThemeProvider theme={currentTheme}>
        <GlobalStyle />
        <Router>
          <Switch>
            <Route path="/" exact>
              <Header theme={theme} toggleThemes={toggleThemes} />
              <Home />
              <Footer theme={theme} />
            </Route>
            <Route path="/blog">
              <Blog />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route>
              <ErrorPage />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
