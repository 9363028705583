import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaBookOpen,
  FaShoppingCart,
  FaLightbulb,
  FaUserEdit,
  FaCity,
} from "react-icons/fa";
import {
  AiTwotonePhone,
  AiTwotoneEnvironment,
  AiTwotoneMail,
  AiFillVideoCamera,
} from "react-icons/ai";

export const servicesData = [
  {
    title: "Industrial",
    description:
      "We can provide solutions that make training and maintenance cost and time efficient.",
  },
  {
    title: "Education",
    description:
      "VR and AR make the learning process more entertaining and interesting, prevent boredom and focus loss.",
  },
  {
    title: "E-commerce",
    description:
      "Provide solutions that let the user be more confident shopping on internet. The different features possible increases the user’s satisfaction and active time in the platform",
  },
  {
    title: "Advertisement",
    description:
      "XR technologies open new doors toward advertisement, providing new ways to display products and get more engagement from the users.",
  },
  {
    title: "Real-estate",
    description:
      "Provide your costumer a virtual tour of the property’s inside and surroundings, letting him feel free to decorate or change colors and materials as if he already owns it.",
  },
  {
    title: "Consulting",
    description:
      "We can assist you to developpe your activity to the next level, and find out how XR technologies can benefit your business.",
  },
];

export const contactInfo = {
  email: "Bocal@vsight.ma",
  phone: "+212 679-828-546",
  adress: "1337 Labs",
};

export const links = ["about", "services", "team", "contact"];
export const footerIcons = [
  <FaFacebook />,
  <FaInstagram />,
  <FaLinkedin />,
  <FaTwitter />,
];
export const servicesIcons = [
  <FaLightbulb className="icon" />,
  <FaBookOpen className="icon" />,
  <FaShoppingCart className="icon" />,
  <AiFillVideoCamera className="icon" />,
  <FaCity className="icon" />,
  <FaUserEdit className="icon" />,
];
export const contactInfoIcons = [
  <AiTwotoneMail className="icon" />,
  <AiTwotoneEnvironment className="icon" />,
  <AiTwotonePhone className="icon" />,
];
