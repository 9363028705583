import React from "react";
import { Particles } from "react-particles-js";
import styled from "styled-components";

function ParticlesEffect() {
  return (
    <StyledParticles
      params={{
        particles: {
          number: {
            value: 70,
            density: {
              enable: true,
              value_area: 1262.6960138265217,
            },
          },
          color: {
            value: "#ffffff",
          },
          shape: {
            type: "circle",
            stroke: {
              width: 5,
              color: "#c3bbbb",
            },
            polygon: {
              nb_sides: 4,
            },
          },
          opacity: {
            value: 0.09614460148062694,
            random: true,
            anim: {
              enable: true,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 9,
            random: true,
            anim: {
              enable: true,
              speed: 30,
              size_min: 0.1,
              sync: true,
            },
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 8.012050123385578,
            direction: "none",
            random: true,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: true,
              mode: "repulse",
            },
            onclick: {
              enable: true,
              mode: "bubble",
            },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 400,
              size: 10,
              duration: 1,
              opacity: 0.6,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      }}
    />
  );
}

export default ParticlesEffect;

const StyledParticles = styled(Particles)`
  height: 100%;
  @media screen and (max-width: 880px) {
    opacity: 0.8;
  }
`;
