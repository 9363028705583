import styled from "styled-components";
import ContactCardInfo from "../UI/ContactInfoCard";
import { NoBorderButton } from "../UI/Button";
import { contactInfo } from "../vsight-data";
import { useTranslation } from "react-i18next";
import emailjs from "emailjs-com";
import { useState } from "react";

function Contact() {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    if (name !== "" && email !== "" && subject !== "" && message !== "") {
      emailjs
        .sendForm(
          "service_rh3qd22",
          "template_l48l4c7",
          e.target,
          "user_Cdx9C5v24BskZUgHWwyUL"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );

      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
      alert("Your message was successfully sent");
    }

    alert("Please fill the form");
  };

  return (
    <Container id="contact">
      <ContactTop>
        <Content>
          <h2 className="title">{t("contact")}</h2>
        </Content>
      </ContactTop>
      <Info>
        <ContactCardInfo
          icon={0}
          title={t("ourEmail")}
          info={contactInfo.email}
          href={"mailto:" + contactInfo.email}
        />
        <ContactCardInfo
          icon={1}
          title={t("ourAdress")}
          info={contactInfo.adress}
          href={"https://goo.gl/maps/RJ5YNHrkP1KB2vNi6"}
        />
        <ContactCardInfo
          icon={2}
          title={t("ourPhone")}
          info={contactInfo.phone}
          href={"tel:" + contactInfo.phone}
        />
      </Info>
      <ContactBottom>
        <ContactImage>
          <img src="/assets/contact.svg" alt="" />
        </ContactImage>
        <ContactForm>
          <Form onSubmit={sendEmail}>
            <input
              type="text"
              placeholder={t("formName")}
              name="from_name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="email"
              placeholder={t("formEmail")}
              name="from_email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="text"
              placeholder={t("formSubject")}
              name="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            <textarea
              name="message"
              id=""
              cols="30"
              rows="9"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder={t("formMessage")}
            ></textarea>
            <NoBorderButton type="submit">{t("send")}</NoBorderButton>
          </Form>
        </ContactForm>
      </ContactBottom>
    </Container>
  );
}

export default Contact;

const Container = styled.div`
  padding: 60px 0;
`;
const ContactTop = styled.div`
  width: 100%;
  text-align: center;
  padding: 40px 0;
  height: 40vh;
  background: linear-gradient(to top right, #710891, #5a099c);

  .title {
    color: #eee;
    font-size: 3em;
    margin-top: 40px;
  }

  @media screen and (max-width: 880px) {
    .title {
      font-size: 2em;
    }
  }
`;
const Content = styled.div``;
const Info = styled.div`
  width: 68%;
  margin: -130px auto 0;
  display: flex;
  background-color: ${({ theme }) => theme.cardBackground};
  @media screen and (max-width: 880px) {
    width: 90%;
    margin: -90px auto 0;
    display: block;
    background-color: transparent;
  }
`;
const ContactBottom = styled.div`
  padding: 40px 0;
  width: 70%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 880px) {
    width: 90%;
    display: block;
  }
`;
const ContactImage = styled.div`
  width: 50%;
  img {
    width: 100%;
    object-fit: contain;
    height: 100%;
  }

  @media screen and (max-width: 880px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;
const ContactForm = styled.div`
  padding: 0 20px;
  width: 50%;
  @media screen and (max-width: 880px) {
    width: 100%;
  }
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  input {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: none;
    outline: none;
    color: ${({ theme }) => theme.paragraphs};
    border-bottom: 2px solid ${({ theme }) => theme.mainColor};
    background-color: transparent;
  }
  textarea {
    margin-bottom: 20px;
    border: none;
    outline: none;
    color: ${({ theme }) => theme.paragraphs};
    border-bottom: 2px solid ${({ theme }) => theme.mainColor};
    border-left: 2px solid ${({ theme }) => theme.mainColor};
    border-right: 2px solid ${({ theme }) => theme.mainColor};
    background-color: transparent;
    padding: 10px;
  }
  @media screen and (max-width: 880px) {
    width: 100%;
  }
`;
