import { useState } from "react";
import styled from "styled-components";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { FaAngleDown } from "react-icons/fa";
import { HiTranslate } from "react-icons/hi";

const ChangeLanguage = ({ setShowMenu, scroll }) => {
  const { t } = useTranslation();
  const [showLangs, setShowLangs] = useState(false);
  const languages = [
    {
      code: "fr",
      name: "Français",
      country_code: "fr",
      imgUrl: "https://www.countryflags.io/fr/flat/32.png",
    },
    {
      code: "en",
      name: "English",
      country_code: "gb",
      imgUrl: "https://www.countryflags.io/us/flat/32.png",
    },
  ];

  return (
    <Select>
      <Globe onClick={() => setShowLangs(!showLangs)} active={scroll}>
        <HiTranslate className="trans" />
        <span>{t("lang")}</span>
        <FaAngleDown className="arrow" />
      </Globe>
      <LangList>
        {showLangs &&
          languages.map(({ code, name, country_code, imgUrl }) => (
            <li key={code}>
              <button
                onClick={() => {
                  i18next.changeLanguage(country_code);
                  setShowLangs(false);
                  setShowMenu(false);
                }}
              >
                <img src={imgUrl} alt="" />
                <span>{name}</span>
              </button>
            </li>
          ))}
      </LangList>
    </Select>
  );
};

export default ChangeLanguage;

const Select = styled.div`
  width: 100px;
  margin-right: 39px;
  position: relative;
  @media screen and (max-width: 880px) {
    margin: 0 40px 30px;
  }
`;
const Globe = styled.button`
  display: flex;
  align-items: center;
  outline: none;
  background: transparent;
  border: none;
  color: ${(props) =>
    props.active
      ? ({ theme }) => theme.linkMenu
      : ({ theme }) => theme.linkColor};
  .trans {
    font-size: 1.9em;
    margin-right: 2px;
  }
  span {
    font-size: 15px;
    margin-right: 2px;
  }
  .arrow {
    font-size: 1.6em;
  }

  @media screen and (max-width: 880px) {
    color: ${({ theme }) => theme.linkMenu};
  }
`;

const LangList = styled.ul`
  position: absolute;
  z-index: 1;
  top: 45px;
  background-color: #19043f;
  li {
    animation: 0.5s move linear forwards;
    transition: all 0.3s linear;
    @keyframes move {
      from {
        transform: translateY(-10px);
        opacity: 0;
      }
      to {
        transform: translateY(5px);
        opacity: 1;
      }
    }
  }
  button {
    display: flex;
    align-items: center;
    width: 110px;
    border: none;
    outline: none;
    margin-bottom: 2px;
    background-color: transparent;
    span {
      margin-left: 7px;
      color: #eee;
    }
  }
`;
