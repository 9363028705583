import styled from "styled-components";

export const BorderButton = (props) => {
  return <BorderButtonStyle>{props.children}</BorderButtonStyle>;
};

export const NoBorderButton = (props) => {
  return (
    <NoBorderButtonStyle onClick={props.readMore}>
      {props.children}
    </NoBorderButtonStyle>
  );
};

const BorderButtonStyle = styled.button`
  padding: 14px 40px;
  font-size: 16px;
  background-color: transparent;
  color: #eee;
  text-transform: uppercase;
  letter-spacing: 1px;
  outline: none;
  border: 1px solid #eee;
  border-radius: 5px;
  transition: all 0.3s linear;
  &:hover {
    background-color: #810cee;
    border: 1px solid #810cee;
    transform: translateY(-4px);
  }
  @media screen and (max-width: 880px) {
    padding: 12px 30px;
    font-size: 15px;
  }
`;

const NoBorderButtonStyle = styled.button`
  padding: 14px 40px;
  font-size: 16px;
  background-color: transparent;
  color: ${({ theme }) => theme.heading};
  text-transform: uppercase;
  letter-spacing: 1px;
  outline: none;
  border: 1px solid ${({ theme }) => theme.heading};
  border-radius: 5px;
  transition: all 0.3s linear;
  &:hover {
    background-color: #810cee;
    border: 1px solid #810cee;
    color: #eee;
    transform: translateY(-4px);
  }
  @media screen and (max-width: 880px) {
    padding: 12px 30px;
    font-size: 15px;
  }
`;
