import { contactInfoIcons } from "../vsight-data";
import styled from "styled-components";

function ContactInfoCard({ icon, info, title, href }) {
  return (
    <Link href={href} target="_blank">
      <Content>
        {contactInfoIcons[icon]}
        <h2>{title}</h2>
        <span>{info}</span>
      </Content>
    </Link>
  );
}

export default ContactInfoCard;

const Link = styled.a`
  width: calc(100% / 3);
  display: inline-block;
  text-align: center;
  padding: 40px;
  background-color: ${({ theme }) => theme.cardBackground};
  transition: all 0.4s linear;
  cursor: pointer;
  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.border};
  }
  .icon {
    font-size: 4em;
    color: ${({ theme }) => theme.mainColor};
    opacity: 0.5;
  }
  h2 {
    color: ${({ theme }) => theme.heading};
    text-transform: uppercase;
    font-weight: 600;
    margin: 20px 0;
  }
  span {
    color: ${({ theme }) => theme.paragraphs};
    font-weight: 400;
    font-size: 1em;
    transition: all 0.3s linear;
  }

  &:hover {
    background: linear-gradient(to top right, #710891, #5a099c);
    h2,
    .icon {
      color: #eee;
      opacity: 1;
    }
    span {
      color: ${({ theme }) => theme.mainColor};
    }
  }

  @media screen and (max-width: 880px) {
    width: 100%;
    margin-bottom: 15px;
    &:not(:last-child) {
      border-right: none;
    }
  }
`;

const Content = styled.div`
  width: 100%;
`;
