import styled from "styled-components";
import { footerIcons } from "../vsight-data";

function TeamCard({ name, imgUrl, job, linkedinUrl }) {
  return (
    <Container>
      <img src={imgUrl} alt={name} />
      <Content>
        <h2>{name}</h2>
        <span className="job">{job}</span>
      </Content>
      <span className="link">{footerIcons[2]}</span>
      <a target="_blank" rel="noreferrer" href={linkedinUrl}>
        {null}
      </a>
    </Container>
  );
}

export default TeamCard;

const Container = styled.div`
  position: relative;
  width: 32%;
  background-color: ${({ theme }) => theme.cardBackground};
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  transition: all 0.4s linear;
  &:hover {
    background: linear-gradient(to top right, #710891, #5a099c);
    transform: translateY(-5px);
    box-shadow: 0 8px 12px -6px ${({ theme }) => theme.cardShadow};
    h2,
    .link {
      color: #eee;
      opacity: 1;
    }
    .job {
      color: #eee;
      border: 2px solid #eee;
    }
    img {
      opacity: 0.9;
    }
  }
  img {
    width: 100%;
    height: 240px;
    object-fit: cover;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  @media screen and (max-width: 880px) {
    width: 100%;
    margin-bottom: 15px;
  }
  .link {
    position: absolute;
    // z-index: 5;
    top: 5px;
    left: 5px;
    color: #fff;
    margin-right: 10px;
    font-size: 2.5em;
    cursor: pointer;
    opacity: 0;
    transition: color 0.2s linear;
    &:hover {
      color: ${({ theme }) => theme.mainColor};
    }
    @media screen and (max-width: 880px) {
      opacity: 1;
      user-select: none;
      // z-index: 0;
    }
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @media screen and (max-width: 880px) {
      top: 5px;
      left: 5px;
      width: 40px;
      height: 40px;
    }
  }
`;

const Content = styled.div`
  padding: 30px;
  h2 {
    color: ${({ theme }) => theme.heading};
    margin-bottom: 15px;
    font-size: 1.9em;
  }
  span {
    color: ${({ theme }) => theme.mainColor};
    padding: 2px 5px;
    border: 2px solid ${({ theme }) => theme.mainColor};
    font-size: 12px;
    border-radius: 15px;
  }
  @media screen and (max-width: 880px) {
    h2 {
      font-size: 1.7em;
    }
  }
`;
