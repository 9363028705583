import { Link } from "react-scroll";

function CustomLink(props) {
  return (
    <Link
      to={props.linkUrl}
      onClick={props.hideMenu}
      spy={true}
      activeClass="active"
      smooth={true}
      offset={-20}
      duration={700}
    >
      {props.children}
    </Link>
  );
}

export default CustomLink;
