import styled from "styled-components";
import TeamCard from "../UI/TeamCard";
import { useTranslation } from "react-i18next";

const teamLength = ["saad", "youssef", "ahmed"];

function TeamSection() {
  const { t } = useTranslation();
  return (
    <Container id="team">
      <h1>{t("teamTitle")}</h1>
      <Content>
        {teamLength.map((_, idx) => (
          <TeamCard
            key={`persone--${idx}--id`}
            imgUrl={t(`imgUrl${idx + 1}`)}
            name={t(`person${idx + 1}`)}
            job={t(`job${idx + 1}`)}
            linkedinUrl={t(`linkedinUrl${idx + 1}`)}
          />
        ))}
      </Content>
    </Container>
  );
}

export default TeamSection;

const Container = styled.div`
  width: 80%;
  margin: auto;
  padding: 80px 40px;
  h1 {
    color: ${({ theme }) => theme.heading};
    font-size: 3em;
    font-weight: 800;
    margin-bottom: 1.2em;
  }

  @media screen and (max-width: 670px) {
    width: 97%;
    padding: 40px 20px;
    h1 {
      font-size: 2.5em;
      text-align: center;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;
