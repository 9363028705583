import styled from "styled-components";
import ToggleButton from "../UI/ToggleButton";
import CustomLink from "../UI/CustomLink";
import { links } from "../vsight-data";
import { animateScroll } from "react-scroll";
import ChangeLanguage from "../UI/ChangeLanguage";
import { useTranslation } from "react-i18next";

function Navbar({ theme, toggleThemes, showMenu, setShowMenu, scroll }) {
  const { t } = useTranslation();

  const scrollToTop = () => {
    animateScroll.scrollToTop();
    setShowMenu(!showMenu);
  };
  return (
    <>
      <Container show={showMenu}>
        <img
          onClick={scrollToTop}
          className="logo"
          src={
            theme === "light"
              ? "/assets/vsight-logo-text.png"
              : "/assets/vsight-logo-text-light.png"
          }
          alt=""
        />
        <NavList scroll={scroll}>
          {links.map((link, idx) => {
            return (
              <li key={`link-${idx}`}>
                <CustomLink
                  hideMenu={() => setShowMenu(!showMenu)}
                  linkUrl={link}
                >
                  {t(`link${idx + 1}`)}
                </CustomLink>
              </li>
            );
          })}
        </NavList>
        <ChangeLanguage setShowMenu={setShowMenu} scroll={scroll} />
        <ToggleButton theme={theme} toggleThemes={toggleThemes} />
      </Container>
    </>
  );
}

export default Navbar;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 610px;
  .logo {
    display: none;
  }

  @media screen and (max-width: 880px) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    padding: 30px 0;
    display: block;
    width: 60%;
    height: 100vh;
    transition: transform 0.4s linear;
    background: ${({ theme }) => theme.menu};
    transform: ${(props) =>
      props.show ? "translateX(0)" : "translateX(-110%)"};

    .logo {
      display: inline;
      width: 70%;
      margin-left: 30px;
      margin-bottom: 20px;
      transition: all 0.3s linear;
    }
  }
`;

const NavList = styled.ul`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0;

  li {
    margin-right: 30px;

    a {
      display: inline-block;
      font-weight: 400;
      color: ${(props) =>
        props.scroll
          ? ({ theme }) => theme.linkMenu
          : ({ theme }) => theme.linkColor};
      cursor: pointer;
      transition: all 0.3s linear;
      &:hover {
        color: ${({ theme }) => theme.mainColor};
      }
    }
  }
  @media screen and (max-width: 880px) {
    flex-direction: column;
    align-items: flex-start;
    li {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
      a {
        text-transform: uppercase;
        width: 100%;
        padding: 10px 50px;
        color: ${({ theme }) => theme.linkMenu};
        font-size: 20px;
        &::after {
          background-color: ${({ theme }) => theme.linkMenu};
        }
      }
    }
  }
`;
