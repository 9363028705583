import styled from "styled-components";

function PageIntro({ title, description }) {
  return (
    <Container>
      <Content>
        <Title>{title}</Title>
        <Paragraph>{description}</Paragraph>
      </Content>
    </Container>
  );
}

export default PageIntro;

const Container = styled.div`
  height: 50vh;
  width: 100%;
  display: grid;
  place-items: center;
  text-align: center;
  background-image: linear-gradient(to top, #30cfd0 0%, #330867 100%);

  @media screen and (max-width: 880px) {
    height: 60vh;
  }
`;
const Content = styled.div`
  padding-top: 12vh;
  height: 100%;
  width: 100%;
  @media screen and (max-width: 880px) {
    padding: 20vh 15px;
  }
`;
const Title = styled.h2`
  color: #bdcfff;
  font-size: 4.5em;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 800;
  @media screen and (max-width: 880px) {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
`;
const Paragraph = styled.p`
  color: #ccc;
  margin: 10px auto 40px;
  width: 750px;
  line-height: 1.6;
  font-size: 18px;
  @media screen and (max-width: 880px) {
    width: 90%;
    font-size: 15px;
  }
`;
