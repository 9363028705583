import styled from "styled-components";
import CustomLink from "../UI/CustomLink";
import { links } from "../vsight-data";
// import { footerIcons } from "../vsight-data";
import { useTranslation } from "react-i18next";

function Footer({ theme }) {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <Content>
          <FooterLeft>
            <img
              src={
                theme === "light"
                  ? "/assets/vsight-logo-text.png"
                  : "/assets/vsight-logo-text-light.png"
              }
              alt="vsight logo"
            />
            <ul>
              {links.map((link, idx) => (
                <li key={`footerLink-${idx}`}>
                  <CustomLink linkUrl={link}>{t(`link${idx + 1}`)}</CustomLink>
                </li>
              ))}
            </ul>
          </FooterLeft>
          <FooterRight>
            <img src="/assets/footer.svg" alt="" />
          </FooterRight>
        </Content>
      </Container>
      <FooterBar>Vsight &copy; 2021 All rights reserved.</FooterBar>
    </>
  );
}

export default Footer;

const Container = styled.div`
  padding: 80px 60px;
  background-color: ${({ theme }) => theme.cardBackground};
  @media screen and (max-width: 880px) {
    padding: 50px 20px;
  }
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  @media screen and (max-width: 880px) {
    display: block;
    width: 100%;
  }
`;
const FooterLeft = styled.div`
  img {
    width: 190px;
    object-fit: contain;
    margin-bottom: 40px;
  }
  li {
    margin-bottom: 10px;
    a {
      color: ${({ theme }) => theme.paragraphs};
      cursor: pointer;
      font-size: 16px;
      transition: all 0.3s linear;
      &:hover {
        color: ${({ theme }) => theme.mainColor};
      }
    }
  }

  @media screen and (max-width: 880px) {
    text-align: center;
    img {
      width: 220px;
      margin: 0 auto 20px;
    }
  }
`;
const FooterRight = styled.div`
  max-width: 400px;
  img {
    width: 100%;
    object-fit: contain;
  }
  @media screen and (max-width: 880px) {
    max-width: 70%;
    margin: 60px auto 0;
    text-align: center;
  }
`;
const FooterBar = styled.div`
  padding: 30px;
  text-align: center;
  background-color: ${({ theme }) => theme.footer};
  color: ${({ theme }) => theme.paragraphs};
`;
