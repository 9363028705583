import styled from "styled-components";
import { Link } from "react-router-dom";
import { NoBorderButton } from "../UI/Button";

function ErrorPage(props) {
  console.log(props);
  return (
    <Container>
      <Content>
        <h2>404</h2>
        <p>Page not found !</p>
        <img src="/assets/vsight-logo.png" alt="" />
        <Link to="/">
          <NoBorderButton>Go Home</NoBorderButton>
        </Link>
      </Content>
    </Container>
  );
}

export default ErrorPage;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
  background-color: ${({ theme }) => theme.backGround};
`;
const Content = styled.div`
  height: max-content;
  min-width: 500px;
  margin: auto;
  padding: 30px;
  text-align: center;
  background-color: ${({ theme }) => theme.cardBackground};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 880px) {
    padding: 20px;
    min-width: 90%;
  }
  h2 {
    color: ${({ theme }) => theme.heading};
    font-size: 7em;
    @media screen and (max-width: 670px) {
      font-size: 6em;
    }
  }
  p {
    color: ${({ theme }) => theme.paragraphs};
  }
  img {
    margin: 30px auto;
    object-fit: contain;
    height: 30vh;
  }
`;
