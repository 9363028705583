import styled from "styled-components";
import { BorderButton } from "../UI/Button";
import Particles from "../UI/Particles";
import { Link } from "react-scroll";
// import { companyTitle } from "../vsight-data";
import { useTranslation } from "react-i18next";

function Landing() {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <Particles />
        <Content>
          <h1>Vsight</h1>
          <p>{t("heading")}</p>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={-20}
            duration={700}
          >
            <BorderButton>{t("headingButton")}</BorderButton>
          </Link>
        </Content>
      </Container>
    </>
  );
}

export default Landing;

const Container = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(
      to right top,
      rgba(51, 2, 63, 0.356),
      rgba(122, 3, 146, 0.199)
    ),
    url("/assets/vr1.jfif");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
`;
const Content = styled.div`
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  h1 {
    color: #bdcfff;
    font-size: 7.5em;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-weight: 800;
  }

  p {
    color: #ccc;
    margin: 10px auto 40px;
    width: 800px;
    line-height: 1.6;
    font-size: 22px;
  }

  @media screen and (max-width: 880px) {
    h1 {
      font-size: 4em;
    }

    p {
      margin: 10px auto 30px;
      width: 90%;
      font-size: 16px;
    }
  }
`;
