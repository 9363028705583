import styled from "styled-components";
import { NoBorderButton } from "../UI/Button";
// import { aboutUs } from "../vsight-data";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function About() {
  const { t } = useTranslation();

  const [more, setMore] = useState(false);
  // const limite = more ? t("about").length : 400;

  // console.log(more);
  return (
    <Container id="about">
      <Content>
        <ImageContainer>
          <img src="/assets/vr-about.svg" alt="" />
        </ImageContainer>
        <TextContainer>
          <h2>{t("aboutTitle")}</h2>
          <p>{t("about")}</p>
          <div className={more ? "toggle show" : "toggle"}>
            <p>{t("about2")}</p>
            <ul>
              <li>{t("xr")}</li>
              <li>{t("vr")}</li>
              <li>{t("ar")}</li>
            </ul>
          </div>
          <NoBorderButton readMore={() => setMore(!more)}>
            {more ? t("less") : t("more")}
          </NoBorderButton>
        </TextContainer>
      </Content>
    </Container>
  );
}

export default About;

const Container = styled.div`
  padding: 4em 2em;
  @media screen and (max-width: 670px) {
    padding: 3em 1em;
  }
`;
const Content = styled.div`
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 670px) {
    width: 95%;
    display: block;
  }

  @media screen and (max-width: 1025px) {
    width: 95%;
  }
`;
const ImageContainer = styled.div`
  position: relative;
  border: none;
  height: 500px;
  width: 40%;
  @media screen and (max-width: 670px) {
    width: 100%;
    height: 300px;
  }
  img {
    animation: smooth 3s ease-in-out infinite;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: all 0.4s linear;
  }

  @keyframes smooth {
    0% {
      top: 0;
    }
    50% {
      top: 20px;
    }
    100% {
      top: 0;
    }
  }
`;
const TextContainer = styled.div`
  width: 50%;
  padding: 3em;

  @media screen and (max-width: 670px) {
    width: 100%;
    padding: 1em;
    text-align: center;
  }
  @media screen and (min-width: 670px) and (max-width: 1024px) {
    width: 60%;
    padding: 2em;
  }
  h2 {
    font-size: 3.7em;
    color: ${({ theme }) => theme.heading};
    margin-bottom: 30px;
    @media screen and (max-width: 670px) {
      font-size: 2.7em;
    }
  }

  p {
    font-size: 16px;
    color: ${({ theme }) => theme.paragraphs};
    margin-bottom: 25px;
    line-height: 1.7;
    transition: all 0.4s linear;
    @media screen and (max-width: 880px) {
      font-size: 15px;
    }
  }

  .toggle {
    display: none;
    opacity: 0;
    transition: all 0.4s linear;
    ul {
      li {
        color: ${({ theme }) => theme.heading};
        font-weight: 700;
        margin: 20px 0;
      }
    }
  }

  .show {
    display: inline;
    opacity: 1;
  }
`;
