import { useState } from "react";
import styled from "styled-components";
import Navbar from "./Navbar";
import BurgerMenu from "../UI/BurgerMenu";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";

function Header({ theme, toggleThemes }) {
  const [scroll, setScroll] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const changeBackgroundOnScroll = () => {
    if (window.scrollY >= 90) {
      setScroll(true);
    } else {
      setScroll(false);
    }

    setShowMenu(false);
  };

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  let src = "/assets/vsight-logo-text-light.png";

  if (scroll && theme === "light") {
    src = "/assets/vsight-logo.png";
  }

  if (scroll && theme !== "light") {
    src = "/assets/vsight-logo-light.png";
  }

  window.addEventListener("scroll", changeBackgroundOnScroll);

  return (
    <Container scroll={scroll}>
      <Mobil>
        <Link to="/">
          <Logo scroll={scroll} onClick={scrollToTop}>
            <img src={src} alt="" />
          </Logo>
        </Link>

        <BurgerMenu
          scroll={scroll}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
        />
      </Mobil>
      <Navbar
        scrollToTop={scrollToTop}
        scroll={scroll}
        showMenu={showMenu}
        theme={theme}
        toggleThemes={toggleThemes}
        setShowMenu={setShowMenu}
      />
    </Container>
  );
}

export default Header;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: ${(props) => (props.scroll ? "8vh" : "12vh")};
  padding: ${(props) => (props.scroll ? "5px 40px " : "40px 40px")};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background 0.1s linear;
  background-color: ${(props) =>
    props.scroll ? ({ theme }) => theme.backgroundOnScroll : "transparent"};
  z-index: 50;
  box-shadow: ${(props) => (props.scroll ? ` 0 8px 12px -6px #333` : "none")};

  @media screen and (max-width: 790px) {
    display: block;
    padding: 0;
  }
`;
const Logo = styled.div`
  width: ${(props) => (props.scroll ? "50px" : "11em")};
  transition: all 0.3s linear;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media screen and (max-width: 880px) {
    width: ${(props) => (props.scroll ? "45px" : "9em")};
    margin-top: 4px;
  }
`;
const Mobil = styled.div`
  @media screen and (max-width: 880px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 0 20px;
  }
`;
