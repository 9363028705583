import PageIntro from "../UI/PageIntro";

function About() {
  return (
    <>
      <PageIntro
        title="About us"
        description="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nihil repellat quis dolores a officia hic, ab neque qui debitis, earum quam quidem."
      />
    </>
  );
}

export default About;
