import React from "react";
import ServiceCard from "../UI/ServiceCard";
import styled from "styled-components";
import { servicesData } from "../vsight-data";
import { useTranslation } from "react-i18next";

function Services() {
  const { t } = useTranslation();
  return (
    <Section id="services">
      <Container>
        <h1>{t("servicesTitle")}</h1>
        <ServicesContainer>
          {servicesData.map((_, idx) => (
            <ServiceCard
              key={idx}
              icon={idx}
              title={t(`name${idx + 1}`)}
              description={t(`description${idx + 1}`)}
            />
          ))}
        </ServicesContainer>
      </Container>
    </Section>
  );
}

export default Services;

const Section = styled.div`
  padding: 5em 2em;
  @media screen and (max-width: 880px) {
    padding: 3em 1em;
  }
`;
const Container = styled.div`
  width: 80%;
  margin: auto;
  h1 {
    color: ${({ theme }) => theme.heading};
    font-size: 3em;
    font-weight: 800;
    margin-bottom: 1.2em;
  }

  @media screen and (max-width: 880px) {
    width: 100%;
    h1 {
      font-size: 2em;
      text-align: center;
    }
  }
`;

const ServicesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;
